import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class MessagesService {

  public messages:{[key: string]:string} = null;
  public messagesWithArgs:(...keys:string[])=>string = null;

  constructor(private http: HttpClient) {

  }

  load():Promise<{[key: string]:string}> {
    return new Promise((resolve, reject) => {
      this.http
        .get('/core/assets/javascripts/playmessages.js?langRand='+ Math.random(), { responseType: 'text'})
        .subscribe(response => {
          this.messages = eval(response).messages;
          // @ts-ignore
          this.messagesWithArgs = window.playMessages;
          resolve(this.message);
        })
    })
  }
  get message(){
    return this.messages;
  }
}
